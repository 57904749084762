@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&amp;family=Jost:wght@300;400;500&amp;display=swap");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  line-height: 1.75;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  color: #4c4d56;
  outline: none;
  visibility: visible;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Barlow", sans-serif;
  color: #0e0e0e;
  font-weight: 700;
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
}

h1 {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a,
button,
img,
input {
  transition: all 0.3s linear;
  outline: 0;
}
img {
  max-width: 100%;
}
a:focus,
button:focus,
img:focus,
input:focus {
  outline: 0;
}

a:focus {
  color: none;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #086ad8;
}

ul,
ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

button,
input[type="submit"] {
  cursor: pointer;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

/*-- Common Classes --*/
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

/*--Section Spacing--*/
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 1199px) {
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
  .section-padding-02 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

.section-padding-03 {
  padding-top: 140px;
}

@media only screen and (max-width: 1199px) {
  .section-padding-03 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .section-padding-03 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-03 {
    padding-top: 60px;
  }
}

/*--Section Title--*/
.section-title .title {
  font-size: 48px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
    line-height: 36px;
  }
}
.hero-content h2 {
  font-size: 3rem !important;
}

.section-title .title.white {
  color: #ffffff;
}

.section-title .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.section-title .sub-title.color-2 {
  color: #ff5538;
}

.section-title .sub-title.color-3 {
  color: #11ddf5;
}

.section-title .sub-title.white {
  color: #ffffff;
}

/*--Section Title Two--*/
.section-title2 {
  max-width: 690px;
  margin: 0 auto;
}

.section-title2 .title {
  font-size: 48px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  color: #333333;
}

.section-title2 .title span {
  color: #086ad8;
}

@media only screen and (max-width: 1199px) {
  .section-title2 .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title2 .title {
    font-size: 28px;
    line-height: 36px;
  }
}

.section-title2 .sub-title {
  font-size: 12px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  color: #a1a1a1;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-title2 p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #4c4d56;
  padding: 0 50px;
  margin-top: 20px;
}

/*--
/*  2.3 - Button CSS
/*----------------------------------------*/
.btn-margin {
  margin-top: 40px;
}

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.btn-wrap .btn {
  margin: 5px;
}

.btn {
  display: inline-block;
  overflow: hidden;
  transform: perspective(0) translateZ(0);
  background: linear-gradient(195deg, #086ad8 0%, #42b9ff 100%);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  border-color: transparent;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  height: 60px;
  line-height: 56px;
  padding: 0px 70px;
  border: 0;
  border-style: solid;
  box-shadow: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.btn::before {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn::after {
  content: "";
  background: #0e0e0e;
  height: 50%;
  width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: auto;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: -1;
}

.btn:focus,
.btn:focus:active {
  box-shadow: none;
}

.btn:hover {
  color: #ffffff;
}

.btn:hover::before {
  width: 100%;
  right: 0;
  left: auto;
}

.btn:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.btn-3 {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding: 0 40px;
  background: #ff5538;
  color: #ffffff;
}

.btn-4 {
  height: 50px;
  line-height: 48px;
  font-size: 18px;
  padding: 0 45px;
  background: #11ddf5;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  color: #ffffff;
}

.btn-4:hover {
  background: #ffffff;
  color: #11ddf5;
}

.btn-round {
  border-radius: 100px;
}

.btn-square {
  border-radius: 0;
}

[class*="btn-icon-"] i {
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  line-height: 28px;
  text-align: center;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

.btn-icon-left {
  padding-left: 69px;
}

.btn-icon-left i {
  left: 0;
  right: auto;
}

.btn-icon-right {
  padding-right: 69px;
}

.btn-icon-right i {
  left: auto;
  right: 0;
}

.btn-primary {
  background-color: #086ad8;
  border-color: #086ad8;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #086ad8;
  border-color: #086ad8;
}

.btn-secondary {
  background-color: #000c29;
  border-color: #000c29;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #000c29;
  border-color: #000c29;
}

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #0e0e0e;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
}

.btn-white::after {
  background: #086ad8;
}

.btn-white::before {
  background: #086ad8;
}

/* swiper-wrapper */
.swiper-wrapper {
  padding-top: 100px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

/*--Swiper Pagination--*/
.swiper-pagination .swiper-pagination-bullet {
  border-radius: 50%;
  background-color: #e8e8e8;
  width: 7px;
  height: 7px;
  transition: all 0.3s linear;
  position: relative;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  border: 1px solid #57585a;
  transform: scale(0);
  width: calc(100% + 14px);
  height: calc(100% + 14px);
  border-radius: 50%;
  transition: all 0.3s linear;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #086ad8;
}

.swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  transform: scale(1);
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
/*----------------------------------------*/
/*  02. Elements CSS
/*----------------------------------------*/
/*--
/*  2.4 - Form CSS
/*----------------------------------------*/
label {
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  font-weight: 400;
  text-transform: capitalize;
}

/*Input Field & Select*/
input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ),
textarea {
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #415674;
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 10px 25px;
  max-width: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.3s linear;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):focus,
textarea:focus {
  outline: none;
  border-color: #086ad8;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}

input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 13px;
  color: #898c94;
  font-weight: 600;
}
/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
/*--
/*  2.1 - Header Top CSS
/*----------------------------------------*/
.header-section {
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  width: 100%;
  z-index: 999;
}

.header-wrap {
  margin-top: 10px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.header-logo a img {
  width: 150px;
}

.logo {
  width: 100px;
  height: 90px;
}
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

@media only screen and (max-width: 1199px) {
  .header-logo a img {
    width: 130px;
  }
}

.header-menu .main-menu {
  display: flex;
}

.header-menu .main-menu li {
  position: relative;
  padding: 30px 25px;
}

@media only screen and (max-width: 1199px) {
  .header-menu .main-menu li {
    padding: 30px 20px;
  }
}

.header-menu .main-menu li a {
  font-size: 15px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  color: #0e0e0e;
}

.header-menu .main-menu li.active-menu > a {
  color: #086ad8;
}

.header-menu .main-menu li.active-menu > .menu-icon::after {
  color: #086ad8;
}

.header-menu .main-menu li .menu-icon::after {
  content: "\f16c";
  font-family: "flaticon";
  font-size: 10px;
  color: #94a0b9;
  margin-left: 5px;
  transition: all 0.3s linear;
}

.header-menu .main-menu li:hover > .menu-icon::after {
  color: #086ad8;
}

.header-menu .main-menu li:hover > a {
  color: #086ad8;
}

.header-menu .main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-menu .main-menu li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 270px;
  background: #ffffff;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  border-radius: 5px;
  border-top: 3px solid #086ad8;
  opacity: 0;
  z-index: 99;
  visibility: hidden;
  transition: all 0.3s linear;
}

.header-menu .main-menu li .sub-menu li {
  padding: 0;
}

.header-menu .main-menu li .sub-menu li a {
  padding: 5px 25px;
  font-size: 15px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  position: relative;
  z-index: 1;
}

.header-menu .main-menu li .sub-menu li.active > a {
  color: #086ad8;
}

.header-menu .main-menu li .sub-menu li:hover > a {
  color: #086ad8;
}

.header-meta {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.header-btn .btn {
  height: 45px;
  line-height: 45px;
  padding: 0 45px;
}

.header-toggle {
  margin-left: 30px;
}

.header-toggle button {
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle button span {
  width: 25px;
  height: 2px;
  background: #086ad8;
  margin: 5px 0;
  display: block;
}

.header-toggle button span:last-child {
  margin-bottom: 0;
}

.header-section-02 {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-section-02.sticky {
  background: #ffffff;
  border-bottom: 0;
}

.header-section-02.sticky .header-wrap .header-logo .logo-black {
  display: block;
}

.header-section-02.sticky .header-wrap .header-logo .logo-white {
  display: none;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li a {
  color: #0e0e0e;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > a {
  color: #ff5538;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > .menu-icon::after {
  color: #ff5538;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .menu-icon::after {
  color: #94a0b9;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li:hover
  > .menu-icon::after {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li:hover > a {
  color: #ff5538;
}

.header-section-02.sticky .header-wrap .header-menu .main-menu li .sub-menu {
  border-top: 3px solid #ff5538;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li.active
  > a {
  color: #ff5538;
}

.header-section-02.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li:hover
  > a {
  color: #ff5538;
  padding-left: 35px;
}

.header-section-02 .header-logo .logo-black {
  display: none;
}

.header-section-02 .header-menu .main-menu > li > a {
  color: #ffffff;
}

.header-section-02 .header-menu .main-menu > li.active-menu > a {
  color: #ff5538;
}

.header-section-02
  .header-menu
  .main-menu
  > li.active-menu
  > .menu-icon::after {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .menu-icon::after {
  color: #ffffff;
}

.header-section-02 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li:hover > a {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu {
  border-top: 3px solid #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu li.active > a {
  color: #ff5538;
}

.header-section-02 .header-menu .main-menu > li .sub-menu li:hover > a {
  color: #ff5538;
}

.header-section-02 .header-btn .btn.btn-3:hover {
  background: #ffffff;
  color: #ff5538;
}

.header-section-04 {
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header-section-04.sticky {
  background: #ffffff;
  border-bottom: 0;
}

.header-section-04.sticky .header-wrap .header-logo .logo-black {
  display: block;
}

.header-section-04.sticky .header-wrap .header-logo .logo-white {
  display: none;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li a {
  color: #0e0e0e;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > a {
  color: #086ad8;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li.active-menu
  > .menu-icon::after {
  color: #086ad8;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .menu-icon::after {
  color: #94a0b9;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li:hover
  > .menu-icon::after {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li:hover > a {
  color: #086ad8;
}

.header-section-04.sticky .header-wrap .header-menu .main-menu li .sub-menu {
  border-top: 3px solid #086ad8;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li.active
  > a {
  color: #086ad8;
}

.header-section-04.sticky
  .header-wrap
  .header-menu
  .main-menu
  li
  .sub-menu
  li:hover
  > a {
  color: #086ad8;
  padding-left: 35px;
}

.header-section-04 .header-logo .logo-black {
  display: none;
}

.header-section-04 .header-menu .main-menu > li > a {
  color: #ffffff;
}

.header-section-04 .header-menu .main-menu > li.active-menu > a {
  color: #086ad8;
}

.header-section-04
  .header-menu
  .main-menu
  > li.active-menu
  > .menu-icon::after {
  color: #086ad8;
}

.header-section-04 .header-menu .main-menu > li .menu-icon::after {
  color: #ffffff;
}

.header-section-04 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #086ad8;
}

.header-section-04 .header-menu .main-menu > li:hover > a {
  color: #086ad8;
}

.header-section-05 .header-social {
  position: relative;
  padding-right: 25px;
  margin-right: 20px;
}

.header-section-05 .header-social::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #ebebeb;
  width: 1px;
  height: 30px;
}

.header-section-05 .header-social ul li {
  display: inline-block;
}

.header-section-05 .header-social ul li + li {
  margin-left: 10px;
}

.header-section-05 .header-social ul li a {
  font-size: 15px;
  display: block;
  color: #565656;
}

.header-section-05 .header-social ul li a:hover {
  color: #086ad8;
}

.header-top-section {
  background: #030213;
}

.header-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.header-top-wrapper .header-top-info ul li {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  position: relative;
}

.header-top-wrapper .header-top-info ul li i {
  font-size: 20px;
  color: #11ddf5;
  position: relative;
  top: 1px;
  margin-right: 7px;
}

.header-top-wrapper .header-top-info ul li + li {
  padding-left: 30px;
  margin-left: 25px;
}

.header-top-wrapper .header-top-info ul li + li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #353542;
  width: 1px;
  height: 45px;
  transform: translateY(-46%);
}

.header-top-wrapper .header-social {
  display: flex;
  align-items: center;
}

.header-top-wrapper .header-social span {
  font-size: 14px;
  color: #11ddf5;
  display: inline-block;
  margin-right: 20px;
}

.header-top-wrapper .header-social ul li {
  display: inline-block;
}

.header-top-wrapper .header-social ul li + li {
  margin-left: 10px;
}

.header-top-wrapper .header-social ul li a {
  display: inline-block;
  font-size: 14px;
  color: #8c8c8c;
}

.header-top-wrapper .header-social ul li a:hover {
  color: #11ddf5;
}

.header-section-06 .header-menu {
  margin-left: auto;
}

.header-section-06 .header-menu .main-menu > li.active-menu > a {
  color: #11ddf5;
}

.header-section-06
  .header-menu
  .main-menu
  > li.active-menu
  > .menu-icon::after {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .menu-icon::after {
  color: #94a0b9;
}

.header-section-06 .header-menu .main-menu > li:hover > .menu-icon::after {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li:hover > a {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu {
  border-top: 3px solid #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu li.active > a {
  color: #11ddf5;
}

.header-section-06 .header-menu .main-menu > li .sub-menu li:hover > a {
  color: #11ddf5;
}

.header-section-06 .header-meta {
  position: relative;
  padding-left: 15px;
}

.header-section-06 .header-meta::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #cfcfcf;
  width: 1px;
  height: 25px;
}

.header-section-06 .header-btn .btn {
  height: 50px;
  line-height: 48px;
}

.header-section-06 .header-btn .btn:hover {
  background: #0f0948;
}

.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
/*--
/*  2.3 - Header Top CSS
/*----------------------------------------*/
.offcanvas {
  background: #000c29;
  width: 320px;
}

@media only screen and (max-width: 575px) {
  .offcanvas {
    width: 280px;
  }
}

.offcanvas-header .close-btn {
  background: none;
  border: 0;
  font-size: 18px;
  color: #ffffff;
  transition: all 0.3s linear;
}

.offcanvas-header .close-btn:hover {
  transform: rotate(90deg);
  color: #086ad8;
}

.offcanvas-logo a img {
  width: 130px;
}

.offcanvas-menu {
  padding: 30px 0;
}

.offcanvas-menu .main-menu li {
  position: relative;
}

.offcanvas-menu .main-menu li a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  color: #ffffff;
  padding: 5px 0px;
  position: relative;
}

.offcanvas-menu .main-menu li .menu-expand {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.offcanvas-menu .main-menu li .menu-expand::before {
  position: absolute;
  content: "";
  width: 16px;
  height: 2px;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li .menu-expand::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 16px;
  left: 50%;
  top: 50%;
  bottom: 0;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.offcanvas-menu .main-menu li:hover > a {
  color: #086ad8;
}

.offcanvas-menu .main-menu li.active > .menu-expand::after {
  height: 0;
}

.offcanvas-menu .main-menu li ul {
  padding-left: 6%;
  display: none;
}
#responsive-navbar-nav {
  justify-content: end;
  align-items: center;
}
.navbar {
  color: black;
}
.navbar.sticky-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: white; /* Adjust z-index as needed */
  /* Add any other styles you want for your sticky navbar */
}

.nav-item {
  font-size: 0.9rem;
  font-weight: 600;
  color: black !important;
}
.nav-item:hover {
  color: #11ddf5 !important;
}
@media (min-width: 990px) {
  .nav-items {
    align-items: center;
    gap: 30px !important;
  }
  .nav-item {
    margin-left: 30px;
  }
}
@media (min-width: 768px) {
  .nav-items {
    align-items: center;
    gap: 10px;
  }
  .nav-item {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .nav-items {
    gap: 20px !important;
  }
}
/* section 1 css  */

.taskplus-hero-section-06 {
  background-image: url("./Assets/images/bg/hero-bg6.jpg");
  /* background: #0f0948; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* padding-top: 130px; */
}

.taskplus-hero-section-06 .hero-images {
  padding-top: 105px;
  position: relative;
  z-index: 1;
}

.taskplus-hero-section-06 .hero-images .shape-1 {
  position: absolute;
  left: 5%;
  top: 17%;
  -webkit-animation: round-01 7s linear infinite;
  animation: round-01 7s linear infinite;
  z-index: -1;
}

.taskplus-hero-section-06 .hero-content {
  padding-top: 170px;
}

.taskplus-hero-section-06 .hero-content .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
}

.taskplus-hero-section-06 .hero-content .title {
  font-size: 70px;
  line-height: 78px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 10px;
}

.taskplus-hero-section-06 .hero-content .title span {
  color: #11ddf5;
}

.taskplus-hero-section-06 .hero-content p {
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 40px;
}

.taskplus-hero-section-06 .hero-content .hero-btn .btn-4 {
  height: 60px;
  line-height: 58px;
  padding: 0 65px;
  margin-top: 60px;
}

@-webkit-keyframes round-01 {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@keyframes round-01 {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@media only screen and (max-width: 1399px) {
  .taskplus-hero-section-06 .hero-images .shape-1 {
    left: 2%;
  }
  .taskplus-hero-section-06 .hero-content .title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1199px) {
  .taskplus-hero-section-06 .hero-images .shape-1 {
    left: 2%;
  }
  .taskplus-hero-section-06 .hero-content {
    padding-top: 120px;
  }
  .taskplus-hero-section-06 .hero-content .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-hero-section-06 .hero-content {
    padding-top: 40px;
  }
  .taskplus-hero-section-06 .hero-images .shape-1 {
    left: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-hero-section-06 .hero-images {
    padding-top: 80px;
  }
  .taskplus-hero-section-06 .hero-images .shape-1 {
    left: 0;
  }
  .taskplus-hero-section-06 .hero-content .title {
    font-size: 40px;
    line-height: 50px;
  }
  .taskplus-hero-section-06 .hero-content p {
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-hero-section-06 .hero-content .title {
    font-size: 32px;
    line-height: 42px;
  }
}

/* Feature section css  */

.taskplus-features-section-02 .features-wrap-02 {
  margin-top: -140px;
  position: relative;
  z-index: 5;
}

.taskplus-features-section-02 .features-item {
  background: linear-gradient(90deg, #693eaa 0%, #01ebf2 100%);
  padding: 40px 35px;
  padding-right: 90px;
  display: flex;
  border-radius: 10px;
}
.features-item img {
  height: 90px;
  width: 150px;
}

.taskplus-features-section-02 .features-item .features-content {
  margin-left: 40px;
}

.taskplus-features-section-02 .features-item .features-content .title {
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: #ffffff;
}

.taskplus-features-section-02 .features-item .features-content .title:hover a {
  color: #11ddf5;
}

.taskplus-features-section-02 .features-item .features-content p {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 15px;
}

.taskplus-features-section-02 .features-item .features-content .lern-more {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #11ddf5;
  margin-top: 5px;
}

.taskplus-features-section-02 .features-item .features-content .lern-more i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.taskplus-features-section-02
  .features-item
  .features-content
  .lern-more:hover
  i {
  margin-left: 5px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-features-section-02 .features-item {
    padding-right: 38px;
  }
  .taskplus-features-section-02 .features-item .features-content {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .taskplus-features-section-02 .features-wrap-02 {
    margin-top: -90px;
  }
  .taskplus-features-section-02 .features-item {
    padding: 40px 25px;
    padding-right: 25px;
  }
  .taskplus-features-section-02 .features-item .features-content {
    margin-left: 25px;
  }
  .taskplus-features-section-02 .features-item .features-content .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-features-section-02 .features-item {
    align-items: self-start;
    flex-direction: column;
    gap: 25px;
  }
  .taskplus-features-section-02 .features-item .features-content {
    margin-left: 0px;
  }
  .feature-row {
    gap: 30px;
  }
}

@media only screen and (max-width: 990px) {
  .feature-row {
    gap: 20px;
  }
}

/* Section2 css  */

.taskplus-about-section-06 {
  padding-bottom: 200px;
  padding-top: 150px;
}

.taskplus-about-section-06 .about-img {
  position: relative;
  margin-top: 70px;
  z-index: 1;
}

.taskplus-about-section-06 .about-img .shape-1 {
  position: absolute;
  left: 0;
  top: -25%;
  -webkit-animation: round-01 7s linear infinite;
  animation: round-01 7s linear infinite;
  z-index: -1;
}

.taskplus-about-section-06 .about-img .play-btn {
  position: absolute;
  top: -25px;
  right: 135px;
}

.taskplus-about-section-06 .about-img .play-btn a {
  width: 80px;
  height: 80px;
  line-height: 68px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 7px solid #ffffff;
  background: #11ddf5;
  color: #ffffff;
}

.taskplus-about-section-06 .about-img .play-btn a:hover {
  background: #0f0948;
  color: #ffffff;
}

.taskplus-about-section-06 .about-img .image {
  padding-left: 70px;
}

.taskplus-about-section-06 .about-img .image img {
  border-radius: 50%;
}

.taskplus-about-section-06 .about-content-wrap {
  padding-right: 115px;
}

.taskplus-about-section-06 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.taskplus-about-section-06 .about-content-wrap .about-list-wrap {
  /* padding-top: 50px; */
}

.taskplus-about-section-06 .about-content-wrap .about-list-wrap .about-list li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li:last-child {
  margin-bottom: 0;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a {
  font-size: 20px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #0e0e0e;
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-06
    .about-content-wrap
    .about-list-wrap
    .about-list
    li
    a {
    font-size: 18px;
  }
  .taskplus-about-section-06 .about-img .image img {
    height: 300px;
    width: 300px;
  }
  .taskplus-about-section-06 {
    padding-bottom: 200px;
    padding-top: 50px;
  }
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a:hover {
  color: #11ddf5;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a:hover
  i {
  transform: translateX(20px);
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a
  i {
  transform: translateX(15px);
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-06 {
    padding-bottom: 100px;
  }
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -13%;
  }
  .taskplus-about-section-06 .about-img .image {
    padding-left: 30px;
  }
  .taskplus-about-section-06 .about-content-wrap {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -25%;
    left: 11%;
  }
  .taskplus-about-section-06 .about-img .image {
    text-align: center;
    padding-left: 0px;
  }
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 150px;
  }
  .taskplus-about-section-06 .about-content-wrap .about-list-wrap {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -20%;
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -10%;
  }
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 80px;
  }
}

/* Section3 css  */

.taskplus-service-section-06 {
  background-image: url("./Assets/images/bg/service-bg6.jpg");
  /* background: #eaf3f9; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 100px;
}

.taskplus-service-section-06 .section-title {
  max-width: 650px;
  margin: 0 auto;
}
.bill {
  font-size: 1.3rem;
  padding-top: 10px;
  color: #086ad8;
  font-weight: 600;
}
.bill-content {
  padding-top: 20px;
}
.list-style {
  list-style-type: disc;
  padding-left: 12px;
}
.taskplus-service-section-06 .service-content-wrap {
  padding-top: 30px;
}

.service-item-05 {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 51px 45px;
  margin-top: 30px;
}

@media only screen and (max-width: 1399px) {
  .service-item-05 {
    padding: 40px 35px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .service-item-05 {
    padding: 40px 30px 45px;
  }
}

.service-item-05 .service-content .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 25px;
}

/* section4 css  */

.taskplus-service-section-04 .service-top .experience-wrap {
  position: relative;
}

.taskplus-service-section-04 .service-top .experience-wrap .shape-1 {
  position: absolute;
  left: 0;
  top: -35px;
  z-index: -1;
}

.taskplus-service-section-04 .service-top .experience-wrap .experience {
  width: 370px;
  height: 370px;
  background: #ffffff;
  border-radius: 50%;
  padding: 140px 0;
  position: absolute;
  left: 20px;
  top: 20px;
}

.taskplus-service-section-04 .service-top .experience-wrap .experience .number {
  font-size: 200px;
  line-height: 54px;
  font-weight: 600;
  color: #086ad8;
}

.taskplus-service-section-04 .service-top .experience-wrap .experience span {
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  display: inline-block;
  margin-top: 80px;
}

.taskplus-service-section-04 .service-top .service-content {
  padding-right: 205px;
  padding-top: 45px;
}

.taskplus-service-section-04 .service-top .service-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
}

.taskplus-service-section-04 .service-bottom {
  padding-top: 190px;
}

.taskplus-service-section-04 .service-bottom .service-item-03 {
  margin-top: 30px;
}

.taskplus-service-section-04 .service-bottom .service-item-03 .service-img {
  overflow: hidden;
  border-radius: 10px;
}

.taskplus-service-section-04 .service-bottom .service-item-03 .service-img a {
  display: block;
}

.taskplus-service-section-04
  .service-bottom
  .service-item-03
  .service-img
  a
  img {
  transition: all 0.3s linear;
}

.taskplus-service-section-04 .service-bottom .service-item-03 .service-text {
  background: #ffffff;
  padding: 25px;
  padding-left: 20px;
  position: relative;
  margin-top: -85px;
  width: 300px;
  transition: all 0.3s linear;
  z-index: 1;
}

.taskplus-service-section-04
  .service-bottom
  .service-item-03
  .service-text
  .title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 600;
  position: relative;
}

.taskplus-service-section-04
  .service-bottom
  .service-item-03
  .service-text
  .title::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 3px;
  height: 50px;
  transform: translateY(-50%);
}

.taskplus-service-section-04
  .service-bottom
  .service-item-03:hover
  .service-img
  a
  img {
  transform: scale(1.1);
}

.taskplus-service-section-04 .service-bottom .more-service-content {
  margin-top: 50px;
}

.taskplus-service-section-04 .service-bottom .more-service-content p {
  font-size: 16px;
  line-height: 30px;
}

.taskplus-service-section-04 .service-bottom .more-service-content p a {
  font-weight: 600;
  color: #086ad8;
}

.taskplus-service-section-04 .service-bottom .more-service-content p a i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.taskplus-service-section-04 .service-bottom .more-service-content p a:hover i {
  margin-left: 5px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-service-section-04 .service-top .service-content {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-service-section-04 {
    padding-top: 100px;
  }
  .taskplus-service-section-04 .service-top .experience-wrap .shape-1 {
    left: 50%;
    top: -35px;
    transform: translateX(-45%);
  }
  .taskplus-service-section-04 .service-top .experience-wrap .experience {
    position: relative;
    margin: auto;
  }
  .taskplus-service-section-04 .service-top .service-content {
    padding-top: 130px;
  }
  .taskplus-service-section-04 .service-bottom {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-service-section-04 .service-top .experience-wrap .shape-1 {
    width: 370px;
  }
  .taskplus-service-section-04 .service-top .experience-wrap .experience {
    width: 325px;
    height: 325px;
    padding: 110px 0;
  }
  .taskplus-service-section-04
    .service-top
    .experience-wrap
    .experience
    .number {
    font-size: 160px;
  }
  .taskplus-service-section-04 .service-top .experience-wrap .experience span {
    font-size: 22px;
    margin-top: 70px;
  }
  .taskplus-service-section-04 .service-bottom {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-service-section-04 .service-top .experience-wrap .shape-1 {
    width: 320px;
    transform: translateX(-50%);
  }
  .taskplus-service-section-04 .service-top .experience-wrap .experience {
    width: 290px;
    height: 290px;
    padding: 90px 0;
    left: 0;
    top: 0;
  }
  .taskplus-service-section-04
    .service-top
    .experience-wrap
    .experience
    .number {
    font-size: 140px;
  }
  .taskplus-service-section-04 .service-top .experience-wrap .experience span {
    font-size: 20px;
    margin-top: 50px;
  }
  .taskplus-service-section-04 .service-top .service-content {
    padding-top: 100px;
  }
}

.taskplus-service-section-07 {
  padding-bottom: 100px;
}

.taskplus-service-section-07 .section-title {
  max-width: 810px;
  margin: 0 auto;
}

.taskplus-service-section-07 .service-content-wrap {
  padding-top: 80px;
}

.taskplus-service-section-07 .experience-wrap {
  position: relative;
}

.taskplus-service-section-07 .experience-wrap .shape-1 {
  position: absolute;
  left: 102px;
  top: -28px;
  -webkit-animation: round-01 5s linear infinite;
  animation: round-01 5s linear infinite;
  z-index: -1;
}

.taskplus-service-section-07 .experience-wrap .experience {
  width: 370px;
  height: 370px;
  /* background: linear-gradient(90deg, #693eaa 0%, #01ebf2 100%); */
  background-image: url("./Assets/images/shape/exp-bg2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  padding: 130px 90px;
  position: absolute;
  left: 150px;
  top: 20px;
}

.taskplus-service-section-07 .experience-wrap .experience .number {
  font-size: 170px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.taskplus-service-section-07 .experience-wrap .experience span {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  color: #ffffff;
  margin-top: 60px;
  padding-left: 10px;
}

.taskplus-service-section-07 .service-content {
  padding-right: 225px;
}

.taskplus-service-section-07 .service-content .text {
  font-size: 18px;
  line-height: 30px;
}

.taskplus-service-section-07 .service-content .service-list {
  padding-top: 15px;
}

.taskplus-service-section-07 .service-content .service-list ul li {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-top: 20px;
}

.taskplus-service-section-07
  .service-content
  .service-list
  ul
  li
  .service-icon {
  color: #11ddf5;
  margin-right: 8px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-service-section-07 .service-content {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .taskplus-service-section-07 .experience-wrap .shape-1 {
    left: 0px;
    top: -28px;
  }
  .taskplus-service-section-07 .experience-wrap .experience {
    padding: 130px 90px;
    left: 45px;
    top: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-service-section-07 {
    padding-bottom: 100px;
  }
  .taskplus-service-section-07 .experience-wrap .shape-1 {
    left: 115px;
    transform: translateX(-50%);
  }
  .taskplus-service-section-07 .experience-wrap .experience {
    left: 0;
    position: relative;
    margin: 0 auto;
  }
  .taskplus-service-section-07 .service-content {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-service-section-07 {
    padding-bottom: 80px;
  }
  .taskplus-service-section-07 .experience-wrap .shape-1 {
    left: 30px;
    top: -50px;
  }
  .taskplus-service-section-07 .experience-wrap .experience {
    width: 325px;
    height: 325px;
    padding: 105px 70px;
  }
  .taskplus-service-section-07 .experience-wrap .experience .number {
    font-size: 150px;
  }
  .taskplus-service-section-07 .experience-wrap .experience span {
    font-size: 16px;
    margin-top: 50px;
  }
  .taskplus-service-section-07 .service-content {
    margin-top: 120px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-service-section-07 .experience-wrap .shape-1 {
    width: 350px;
    left: 0;
    top: -25px;
  }
  .taskplus-service-section-07 .experience-wrap .experience {
    width: 290px;
    height: 290px;
    padding: 80px 65px;
    left: -10px;
    top: 0px;
  }
  .taskplus-service-section-07 .experience-wrap .experience .number {
    font-size: 140px;
  }
  .taskplus-service-section-07 .experience-wrap .experience span {
    font-size: 16px;
  }
  .taskplus-service-section-07 .service-content {
    margin-top: 85px;
  }
}

/* Section 5 css  */

.taskplus-cta-section-04 {
  background-image: url("./Assets/images/bg/cta-bg3.jpg");
  /* background: #0f0948; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.taskplus-cta-section-04 .cta-left {
  padding-right: 115px;
}

.taskplus-cta-section-04 .cta-left .section-title .title {
  position: relative;
  padding-left: 40px;
}

.taskplus-cta-section-04 .cta-left .section-title .title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 5px;
  height: 100%;
  transform: translateY(-45%);
}

.taskplus-cta-section-04 .cta-left .cta-info {
  display: flex;
  align-items: center;
  margin-top: 60px;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text {
  flex: 1;
  margin-left: 30px;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text p {
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text .number {
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.taskplus-cta-section-04 .cta-right {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 360px;
}

.taskplus-cta-section-04 .cta-right::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 55%;
  background: #27225b;
  width: 360px;
  height: 1px;
  transform: translateY(-50%);
}

.taskplus-cta-section-04 .cta-right::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: #27225b;
  width: 1px;
  height: 280px;
  transform: translate(-50%, -50%);
}

.taskplus-cta-section-04 .cta-right .counter-item-box {
  position: relative;
}

.taskplus-cta-section-04 .cta-right .counter-item.counter-1,
.taskplus-cta-section-04 .cta-right .counter-item.counter-2 {
  padding-top: 45px;
  margin-top: 25px;
}

.taskplus-cta-section-04 .cta-right .counter-item span {
  font-size: 48px;
  line-height: 26px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #11ddf5;
}

.taskplus-cta-section-04 .cta-right .counter-item p {
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-04 .cta-left {
    padding-right: 0px;
  }
  .taskplus-cta-section-04 .cta-right {
    margin-top: 65px;
  }
  .taskplus-cta-section-04 .cta-right::before {
    width: 320px;
  }
  .taskplus-cta-section-04 .cta-right::after {
    height: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-04 .cta-right .counter-item span {
    font-size: 42px;
  }
}

/* Section6 css  */

.taskplus-testimonial-section-02 {
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.taskplus-testimonial-section-02 .testimonial-content-wrap-02 {
  margin-top: 60px;
}

.taskplus-testimonial-section-02 .single-testimonial-02 {
  background: #ffffff;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  width: 570px;
  margin: 0 40px;
  position: relative;
}

.taskplus-testimonial-section-02 .single-testimonial-02 .testimonial-thumb {
  position: absolute;
  left: -7%;
  top: 50%;
  transform: translateY(-65%);
  z-index: 3;
}

.taskplus-testimonial-section-02 .single-testimonial-02 .testimonial-thumb img {
  border-radius: 10px;
}

.taskplus-testimonial-section-02 .single-testimonial-02 .testimonial-content {
  padding: 40px 130px;
  padding-right: 40px;
}

.taskplus-testimonial-section-02 .single-testimonial-02 .testimonial-content p {
  font-size: 20px;
  line-height: 32px;
  color: #4c4d56;
  margin-top: 20px;
  margin-bottom: 15px;
}

.taskplus-testimonial-section-02
  .single-testimonial-02
  .testimonial-content
  .name {
  font-size: 16px;
  line-height: 20px;
  color: #0e0e0e;
}

.taskplus-testimonial-section-02
  .single-testimonial-02
  .testimonial-content
  .designation {
  font-size: 12px;
  line-height: 20px;
  color: #47484a;
}

.taskplus-testimonial-section-03 {
  background-image: url("./Assets/images/shape/testi-shape6.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.taskplus-testimonial-section-03 .single-testimonial-02 {
  margin: 10px 40px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-testimonial-section-03 .single-testimonial-02 {
    margin: 0 auto;
  }
}

.taskplus-testimonial-section-03
  .single-testimonial-02
  .testimonial-thumb::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #086ad8;
  width: 135px;
  height: 135px;
  border-radius: 10px;
  transform: translate(5px, 5px);
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .taskplus-testimonial-section-03
    .single-testimonial-02
    .testimonial-thumb::before {
    left: 50%;
    top: 50%;
    transform: translate(-45%, -31%);
  }
}

.taskplus-testimonial-section-03
  .testimonial-02-active
  .swiper-pagination
  .swiper-pagination-bullet {
  background-color: #e8e8e8;
}

.taskplus-testimonial-section-03
  .testimonial-02-active
  .swiper-pagination
  .swiper-pagination-bullet::after {
  border: 1px solid #57585a;
}

.taskplus-testimonial-section-03
  .testimonial-02-active
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #086ad8;
}

.testimonial-02-active .swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-wrapper {
  padding-bottom: 60px;
}
@media only screen and (max-width: 990px) {
  .swiper-slide {
    width: 200px !important;
  }
  .swiper-wrapper {
    padding-bottom: 40px !important;
    padding-top: 60px !important;
  }
}
/* Section 8  */

.taskplus-cta-section-05 .cta-wrap {
  background-image: url("./Assets/images/bg/cta-bg4.jpg");
  /* background: #0f0948; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: -85px;
  position: relative;
  z-index: 5;
  padding: 80px 110px 105px;
}

.taskplus-cta-section-05 .cta-wrap .cta-content .title {
  font-size: 42px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.taskplus-cta-section-05 .cta-wrap .cta-content .cta-btn .btn-4 {
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 35px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 80px 75px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 70px 70px 70px;
  }
  .taskplus-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 70px 50px 70px;
  }
  .taskplus-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 32px;
    line-height: 44px;
  }
}

/*--
/*  03 - CTA CSS
/*----------------------------------------*/

@media only screen and (max-width: 575px) {
  .taskplus-cta-section .cta-wrap {
    padding: 40px 90px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section .cta-wrap {
    padding: 40px 70px;
  }
}

.taskplus-cta-section .cta-wrap .cta-icon {
  position: absolute;
  left: 30px;
  top: -60px;
  height: 85px;
  width: 85px;
  line-height: 85px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 20px 43px 0px rgba(0, 0, 0, 0.09);
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section .cta-wrap .cta-icon {
    top: -40px;
  }
}

.taskplus-cta-section .cta-wrap .cta-content p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #4c4d56;
}

.taskplus-cta-section .cta-wrap .cta-content p a {
  font-weight: 600;
  color: #086ad8;
}

.taskplus-cta-section .cta-wrap .cta-content p a:hover {
  color: #0e0e0e;
}

.taskplus-cta-section-02 .cta-wrap {
  background-image: url("../src/Assets/images/bg/cta-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 10px;
  padding: 55px 105px;
  margin-bottom: -90px;
}

.taskplus-cta-section-02 .cta-wrap .cta-content p {
  font-size: 30px;
  line-height: 44px;
  font-weight: 600;
  padding-left: 90px;
  color: #ffffff;
}

.taskplus-cta-section-02 .cta-wrap .cta-content .cta-icon {
  width: 115px;
  height: 115px;
  line-height: 110px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -114px;
  left: -40px;
  background: #010f31;
}

.taskplus-cta-section-02 .cta-wrap .cta-btn {
  text-align: right;
}

.taskplus-cta-section-02 .cta-wrap .cta-btn .btn {
  font-size: 22px;
  background: #ffffff;
  color: #0e0e0e;
  padding: 0 35px;
}

.taskplus-cta-section-02 .cta-wrap .cta-btn .btn:hover {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section-02 .cta-wrap .cta-content {
    text-align: center;
    margin-top: 40px;
  }
  .taskplus-cta-section-02 .cta-wrap .cta-content .cta-icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .taskplus-cta-section-02 .cta-wrap .cta-content p {
    padding-left: 0;
  }
  .taskplus-cta-section-02 .cta-wrap .cta-btn {
    text-align: center;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-02 .cta-wrap {
    padding: 55px 60px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section-02 .cta-wrap {
    padding: 55px 25px;
  }
}

.taskplus-cta-section-03 .cta-wrap {
  padding: 90px 105px;
  border-radius: 10px;
  background: #0c155f;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -85px;
  position: relative;
  z-index: 5;
}

.taskplus-cta-section-03 .cta-wrap .cta-content {
  padding-right: 60px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-cta-section-03 .cta-wrap .cta-content {
    padding-right: 30px;
  }
}

.taskplus-cta-section-03 .cta-wrap .cta-content .title {
  padding-left: 30px;
  position: relative;
}

.taskplus-cta-section-03 .cta-wrap .cta-content .title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 5px;
  height: 100%;
  transform: translateY(-45%);
}

.taskplus-cta-section-03 .cta-wrap .cta-info p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #ffffff;
  margin-top: 10px;
}

.taskplus-cta-section-03 .cta-wrap .cta-info .number {
  font-size: 36px;
  line-height: 42px;
  font-weight: 700;
  color: #4bfff4;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-03 .cta-wrap .cta-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section-03 .cta-wrap {
    padding: 90px 75px;
  }
  .taskplus-cta-section-03 .cta-wrap .cta-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section-03 .cta-wrap {
    padding: 70px 40px;
  }
  .taskplus-cta-section-03 .cta-wrap .cta-info .number {
    font-size: 28px;
    line-height: 40px;
  }
}

.taskplus-cta-section-04 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.taskplus-cta-section-04 .cta-left {
  padding-right: 115px;
}

.taskplus-cta-section-04 .cta-left .section-title .title {
  position: relative;
  padding-left: 40px;
}

.taskplus-cta-section-04 .cta-left .section-title .title::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 5px;
  height: 100%;
  transform: translateY(-45%);
}

.taskplus-cta-section-04 .cta-left .cta-info {
  display: flex;
  align-items: center;
  margin-top: 60px;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text {
  flex: 1;
  margin-left: 30px;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text p {
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.taskplus-cta-section-04 .cta-left .cta-info .cta-text .number {
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.taskplus-cta-section-04 .cta-right {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 360px;
}

.taskplus-cta-section-04 .cta-right::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 55%;
  background: #27225b;
  width: 360px;
  height: 1px;
  transform: translateY(-50%);
}

.taskplus-cta-section-04 .cta-right::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: #27225b;
  width: 1px;
  height: 280px;
  transform: translate(-50%, -50%);
}

.taskplus-cta-section-04 .cta-right .counter-item-box {
  position: relative;
}

.taskplus-cta-section-04 .cta-right .counter-item.counter-1,
.taskplus-cta-section-04 .cta-right .counter-item.counter-2 {
  padding-top: 45px;
  margin-top: 25px;
}

.taskplus-cta-section-04 .cta-right .counter-item span {
  font-size: 48px;
  line-height: 26px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #11ddf5;
}

.taskplus-cta-section-04 .cta-right .counter-item p {
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-04 .cta-left {
    padding-right: 0px;
  }
  .taskplus-cta-section-04 .cta-right {
    margin-top: 65px;
  }
  .taskplus-cta-section-04 .cta-right::before {
    width: 320px;
  }
  .taskplus-cta-section-04 .cta-right::after {
    height: 240px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-04 .cta-right .counter-item span {
    font-size: 42px;
  }
}

.taskplus-cta-section-05 .cta-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: -85px;
  position: relative;
  z-index: 5;
  padding: 80px 110px 105px;
}

.taskplus-cta-section-05 .cta-wrap .cta-content .title {
  font-size: 42px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.taskplus-cta-section-05 .cta-wrap .cta-content .cta-btn .btn-4 {
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  padding: 0 30px;
  margin-top: 35px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 80px 75px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 70px 70px 70px;
  }
  .taskplus-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section-05 .cta-wrap {
    padding: 70px 50px 70px;
  }
  .taskplus-cta-section-05 .cta-wrap .cta-content .title {
    font-size: 32px;
    line-height: 44px;
  }
}

.taskplus-cta-section-06 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section-06 .cta-info {
    margin-top: 50px;
  }
}

.taskplus-cta-section-06 .cta-info .cta-text {
  margin-left: 30px;
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section-06 .cta-info .cta-text {
    margin-left: 0;
  }
}

.taskplus-cta-section-06 .cta-info .cta-text p {
  font-size: 18px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #ffffff;
}

.taskplus-cta-section-06 .cta-info .cta-text .number {
  font-size: 36px;
  line-height: 42px;
  color: #086ad8;
}

.taskplus-cta-section-07 {
  background: none;
}

.taskplus-cta-section-07 .cta-wrap {
  padding: 105px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: -150px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .taskplus-cta-section-07 .cta-info .cta-text {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-cta-section-07 .cta-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-cta-section-07 .cta-wrap {
    padding: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-cta-section-07 .cta-wrap {
    padding: 60px;
  }
  .taskplus-cta-section-07 .cta-info .cta-text .number {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-cta-section-07 .cta-wrap {
    padding: 45px;
  }
}

/*--
/*  Footer CSS
/*----------------------------------------*/
.footer-section {
  background-image: url("./Assets/images/bg/footer-bg.jpg");
  background: #010717;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.footer-logo {
  max-width: 170px;
}

.footer-copyright-area {
  border-top: 1px solid #1b202f;
  padding-top: 15px;
  padding-bottom: 30px;
}

.copyright-text {
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .copyright-text {
    text-align: center;
  }
}

.copyright-text p {
  font-size: 14px;
  color: #c4c5c7;
}

.footer-section-02 {
  background: #000000;
}

.footer-section-02 .footer-widget-wrap {
  padding-top: 130px;
}

.footer-section-03 .footer-widget-wrap {
  padding-top: 140px;
}
.blog .footer-section-03 .footer-widget-wrap {
  padding-top: 30px;
}

.footer-section-04 .footer-copyright-area {
  border-top: 1px solid #e0e0e0;
}

.footer-section-04 .copyright-text p {
  font-size: 14px;
  color: #0e0e0e;
}
.footer-widget-wrap {
  padding-top: 70px;
  padding-bottom: 75px;
}

.footer-widget {
  margin-top: 30px;
}

.widget-info {
  padding-top: 20px;
}

.widget-info ul li {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.widget-info ul li .info-icon i {
  display: inline-block;
  color: #086ad8;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}

.widget-info ul li .info-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #c4c5c7;
}

.footer-widget-about {
  margin-top: 15px;
}

.footer-widget-about p {
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 110px;
  margin-top: 20px;
}

@media only screen and (max-width: 1399px) {
  .footer-widget-about p {
    padding-right: 0;
  }
}

.footer-social .social {
  margin-top: 25px;
}

.footer-social .social li {
  display: inline-block;
}

.footer-social .social li + li {
  margin-left: 5px;
}

.footer-social .social li a {
  width: 35px;
  height: 35px;
  line-height: 37px;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  background: #ffffff;
  color: #086ad8;
}

.footer-social .social li a:hover {
  color: #086ad8;
}

.footer-widget .footer-widget-title {
  font-size: 20px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #ffffff;
}

.widget-link {
  padding-top: 22px;
}

.widget-link .link li {
  margin-top: 7px;
}

.widget-link .link li a {
  font-size: 14px;
  color: #c4c5c7;
  transition: all 0.3s linear;
}

.widget-link .link li a:hover {
  color: #086ad8;
  padding-left: 10px;
}

.footer-section-02 .footer-social .social li a {
  color: #ff5538;
}

.footer-section-02 .footer-social .social li a:hover {
  color: #ff5538;
}

.footer-section-02 .widget-link {
  padding-top: 22px;
}

.footer-section-02 .widget-link .link li a:hover {
  color: #ff5538;
}

.footer-section-02 .widget-info ul li .info-icon i {
  color: #ff5538;
}

.footer-section-02 .widget-info ul li .info-text a:hover {
  color: #ff5538;
}

.footer-section-04 .footer-widget-about p {
  color: #0e0e0e;
}

.footer-section-04 .footer-widget .footer-widget-title {
  color: #0e0e0e;
}

.footer-section-04 .widget-link {
  padding-top: 22px;
}

.footer-section-04 .widget-link .link li a {
  color: #0e0e0e;
}

.footer-section-04 .widget-link .link li a:hover {
  color: #086ad8;
}

.footer-section-04 .widget-info ul li .info-text {
  color: #0e0e0e;
}

.footer-section-05 .footer-social .social li a {
  color: #11ddf5;
}

.footer-section-05 .footer-social .social li a:hover {
  color: #11ddf5;
}

.footer-section-05 .widget-link {
  padding-top: 22px;
}

.footer-section-05 .widget-link .link li a:hover {
  color: #11ddf5;
}

.footer-section-05 .widget-info ul li .info-icon i {
  color: #11ddf5;
}

.footer-section-05 .widget-info ul li .info-text a:hover {
  color: #11ddf5;
}
/*--
/*  20 - Page Banner CSS
/*----------------------------------------*/
.page-banner-section {
  background-image: url("./Assets/images/bg/page-banner.jpg");
  padding-top: 85px;
  min-height: 670px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.page-banner-section .shape-1 {
  position: absolute;
  left: -32%;
  top: -35%;
  z-index: -1;
}

.page-banner-section .shape-2 {
  position: absolute;
  left: -11%;
  top: -30%;
  z-index: -1;
}

.page-banner-section .shape-3 {
  position: absolute;
  right: -2%;
  bottom: -27%;
  z-index: -1;
}

.page-banner-section .shape-4 {
  position: absolute;
  right: -26%;
  bottom: -81%;
  z-index: -1;
}
.breadcrumb {
  background: none !important;
}
@media (min-width: 990px) {
  .home .navbar {
    margin-top: 45px;
  }
}
@media only screen and (max-width: 1199px) {
  .page-banner-section {
    min-height: 520px;
  }
  .page-banner-section .shape-1 {
    left: -70%;
    top: -47%;
  }
  .page-banner-section .shape-2 {
    left: -30%;
    top: -55%;
  }
  .page-banner-section .shape-3 {
    right: -15%;
    bottom: -45%;
  }
  .page-banner-section .shape-4 {
    right: -50%;
    bottom: -130%;
  }
}

@media only screen and (max-width: 991px) {
  .page-banner-section {
    min-height: 450px;
  }
  .page-banner-section .shape-1 {
    left: -95%;
    top: -58%;
  }
  .page-banner-section .shape-2 {
    left: -41%;
    top: -79%;
  }
  .page-banner-section .shape-3 {
    right: -25%;
    bottom: -60%;
  }
  .page-banner-section .shape-4 {
    right: -90%;
    bottom: -125%;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-section {
    min-height: 350px;
  }
  .page-banner-section .shape-1 {
    display: none;
  }
  .page-banner-section .shape-2 {
    display: none;
  }
  .page-banner-section .shape-3 {
    display: none;
  }
  .page-banner-section .shape-4 {
    display: none;
  }
}

.page-banner .title {
  font-size: 60px;
  line-height: 1.25;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .page-banner .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .title {
    font-size: 30px;
  }
}

.page-banner .breadcrumb {
  margin-bottom: 0;
  margin-top: 10px;
}

.page-banner .breadcrumb .breadcrumb-item {
  color: #ffffff;
  font-size: 20px;
}

@media only screen and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner .breadcrumb .breadcrumb-item {
    font-size: 16px;
  }
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 8px;
}

.page-banner .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  font-family: flaticon !important;
  font-size: 20px;
  padding-right: 5px;
  color: #ffffff;
}

/* Section2 css  */

.taskplus-about-section-07 .about-img-wrap {
  display: flex;
  position: relative;
  z-index: 1;
}

.taskplus-about-section-07 .about-img-wrap .shape-1 {
  position: absolute;
  right: 55px;
  bottom: -30px;
  z-index: -1;
}

.taskplus-about-section-07 .about-img-wrap .about-img {
  overflow: hidden;
}

.taskplus-about-section-07 .about-img-wrap .about-img img {
  border-radius: 10px;
}

.taskplus-about-section-07 .about-img-wrap .about-img:first-child {
  padding-right: 15px;
}

.taskplus-about-section-07 .about-img-wrap .about-img:last-child {
  padding-left: 15px;
  margin-top: 75px;
}

.taskplus-about-section-07 .about-content-wrap {
  padding-left: 90px;
}

.taskplus-about-section-07 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.taskplus-about-section-07 .about-content-wrap .about-list-03 {
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
  padding-top: 15px;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03 {
  padding-right: 40px;
  margin-top: 25px;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03
  .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #231e32;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03
  p {
  font-size: 16px;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 10px;
    bottom: -30px;
    width: 370px;
  }
  .taskplus-about-section-07 .about-content-wrap {
    padding-left: 40px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-07 .about-img-wrap {
    justify-content: center;
  }
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 75px;
    width: inherit;
  }
  .taskplus-about-section-07 .about-content-wrap {
    padding-left: 0px;
    padding-top: 60px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 40px;
    width: 370px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    display: none;
  }
}

/* Section3 css  */

.taskplus-counter-section-03 {
  /* background: #010642; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 80px;
  padding-bottom: 110px;
  position: relative;
  z-index: 1;
}

.taskplus-counter-section-03::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(1, 1, 63, 0.9);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.single-counter-02 {
  margin-top: 30px;
}

.single-counter-02 span {
  font-size: 50px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  color: #086ad8;
}

.single-counter-02 p {
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
  color: #ffffff;
}

.taskplus-counter-section-04 {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}

.taskplus-counter-section-04::before {
  display: none;
}

.taskplus-counter-section-04 .counter-wrap {
  /* background: linear-gradient(55deg, #693eaa 25%, #47bbff 100%); */

  background-image: url("./Assets/images/bg/counter-bg2.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: -110px;
  padding-top: 65px;
  padding-bottom: 95px;
}

.taskplus-counter-section-04 .counter-wrap .single-counter-02 span {
  color: #ffffff;
}

/* Section4 css   */
/*--
/*  03 - Choose Us CSS
/*----------------------------------------*/
.taskplus-choose-us-section {
  background-image: url("./Assets/images/bg/choose-us-bg3.jpg");
  /* background: #f8f8f8; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 210px;
}

.taskplus-choose-us-section .section-title {
  max-width: 570px;
  margin: 0 auto;
}

.choose-us-content-wrap {
  padding-top: 30px;
}

.choose-us-content-wrap .more-choose-content {
  margin-top: 30px;
}

.choose-us-content-wrap .more-choose-content p {
  font-size: 16px;
  line-height: 30px;
}

.choose-us-content-wrap .more-choose-content p a {
  font-weight: 600;
  color: #086ad8;
}

.choose-us-content-wrap .more-choose-content p a i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.choose-us-content-wrap .more-choose-content p a:hover i {
  margin-left: 5px;
}

.choose-us-item {
  margin-top: 30px;
}

.choose-us-item .choose-us-img {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.choose-us-item .choose-us-img a {
  display: block;
  position: relative;
}

.choose-us-item .choose-us-img a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(249, 80, 0, 0) 35%,
    rgba(14, 14, 14, 0.92) 78%
  );
  width: 100%;
  height: 100%;
  z-index: 0;
}

.choose-us-item .choose-us-img .choose-us-content {
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 60px 60px;
  padding-right: 75px;
}

.choose-us-item .choose-us-img .choose-us-content .title {
  font-size: 24px;
  line-height: 27px;
  color: #ffffff;
  padding-left: 15px;
  position: relative;
}

.choose-us-item .choose-us-img .choose-us-content .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: linear-gradient(-160deg, #43baff 0%, #7141b1 100%);
  width: 3px;
  height: 50px;
  transform: translateY(-50%);
}

.choose-us-item .choose-us-img .choose-us-content p {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: -115px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
}

.choose-us-item:hover .choose-us-img .choose-us-content p {
  opacity: 1;
  visibility: visible;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1399px) {
  .choose-us-item .choose-us-img .choose-us-content {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .choose-us-item .choose-us-img .choose-us-content {
    padding: 40px 30px;
    padding-right: 25px;
  }
}

.taskplus-choose-us-section-02 {
  background: #f8f8f8;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 400px;
  z-index: 1;
}

.taskplus-choose-us-section-02 .choose-us-left {
  padding-right: 50px;
}

.taskplus-choose-us-section-02 .choose-us-left .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
  padding-right: 65px;
}

.taskplus-choose-us-section-02 .choose-us-left .author-skill-wrap {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-right: 40px;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .author-wrap
  .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .author-wrap
  .designation {
  font-size: 12px;
  line-height: 18px;
}

.taskplus-choose-us-section-02 .choose-us-left .author-skill-wrap .skill-wrap {
  flex: 1;
  padding-left: 50px;
  margin-left: 30px;
  position: relative;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .skill-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ececec;
  width: 2px;
  height: 95px;
  transform: translateY(-50%);
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .skill-wrap
  .counter-bar {
  padding-top: 0px;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .skill-wrap
  .counter-bar
  .skill-item {
  margin-top: 15px;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .skill-wrap
  .counter-bar
  .skill-item.skill-item-01 {
  margin-top: 0;
}

.taskplus-choose-us-section-02
  .choose-us-left
  .author-skill-wrap
  .skill-wrap
  .counter-bar
  .skill-item
  .skill-bar
  .bar-inner
  .progress-line {
  background: #086ad8;
}

.taskplus-choose-us-section-02 .choose-us-right {
  padding-left: 10px;
}

@media only screen and (max-width: 991px) {
  .taskplus-choose-us-section-02 .choose-us-right .faq-accordion {
    margin-top: 50px;
  }
}

.taskplus-choose-us-section-02 .choose-us-right .faq-accordion .accordion-item {
  border: 0;
  box-shadow: 0px 0px 161px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 35px;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button {
  padding: 25px 35px;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button
  .title {
  font-size: 22px;
  color: #0e0e0e;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button::after {
  position: absolute;
  content: "+";
  border: none;
  width: 30px;
  height: 30px;
  line-height: 24px;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background: linear-gradient(-140deg, #43baff 0%, #7141b1 100%);
  right: 35px;
  transition: all 0.3s ease-out 0s;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-body {
  font-size: 14px;
  border-top: 1px solid #ebebeb;
  padding: 30px 35px;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button:focus {
  box-shadow: none;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  box-shadow: none;
}

.taskplus-choose-us-section-02
  .choose-us-right
  .faq-accordion
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  position: absolute;
  content: "-";
  border: none;
  width: 30px;
  height: 30px;
  line-height: 24px;
  text-align: center;
  background: #e6eef8;
  color: #0e0e0e;
  transform: rotate(0);
}

@media only screen and (max-width: 1199px) {
  .taskplus-choose-us-section-02 {
    padding-bottom: 350px;
  }
  .taskplus-choose-us-section-02 .choose-us-left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-choose-us-section-02 {
    padding-bottom: 330px;
  }
  .taskplus-choose-us-section-02 .choose-us-right {
    padding-left: 0;
    padding-top: 15px;
  }
}

.choose-us-item-02 {
  background: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 40px 45px 40px;
  margin-top: 30px;
  position: relative;
}

.choose-us-item-02 .choose-us-img img {
  transition: all 0.3s linear;
}

.choose-us-item-02 .choose-us-content .title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 20px;
}

.choose-us-item-02 .choose-us-content p {
  font-size: 16px;
  line-height: 30px;
  padding-top: 20px;
}

.choose-us-item-02:hover .choose-us-img img {
  transform: scale(0.9);
}

.taskplus-choose-us-section-03 {
  background: #ffffff;
  padding-top: 120px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-choose-us-section-03 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-choose-us-section-03 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-choose-us-section-03 {
    padding-top: 60px;
  }
}

.taskplus-choose-us-section-04 {
  background: #ffffff;
  padding-top: 120px;
}

.taskplus-choose-us-section-04 .choose-us-wrap {
  margin-top: -30px;
}

.taskplus-choose-us-section-04 .choose-us-content-wrap {
  padding-top: 0;
}

.taskplus-choose-us-section-04 .choose-us-item-02 {
  border: 1px solid #e1e1e1;
  box-shadow: none;
}

/* Section5 css  */
/*--
/*  03 - Skill CSS
/*----------------------------------------*/
.taskplus-skill-section .skill-left {
  padding-right: 120px;
}

.taskplus-skill-section .skill-left p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 40px;
}

.taskplus-skill-section .skill-left .skill-author {
  margin-top: 30px;
}

.taskplus-skill-section .skill-left .skill-author .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.taskplus-skill-section .skill-left .skill-author .designation {
  font-size: 12px;
  line-height: 18px;
}

.taskplus-skill-section .skill-right {
  padding-right: 50px;
  margin-top: 30px;
}

.taskplus-skill-section .skill-right .experience {
  display: flex;
}

.taskplus-skill-section .skill-right .experience .number {
  font-size: 180px;
  line-height: 30px;
  color: #ff5538;
  margin-right: 10px;
}

.taskplus-skill-section .skill-right .experience span {
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  margin-top: 5px;
}

.counter-bar {
  padding-top: 20px;
}

.counter-bar .skill-item {
  margin-top: 30px;
}

.counter-bar .skill-item .title {
  font-size: 14px;
  line-height: 45px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #120e1d;
}

.counter-bar .skill-item .skill-bar .bar-inner {
  width: 100%;
  height: 6px;
  border-radius: 0px;
  position: relative;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-radius: 0px;
  background: #086ad8;
  transition: 3s;
  transition-delay: 1s;
  width: 0;
}

.counter-bar .skill-item .skill-bar .bar-inner .progress-line .skill-percent {
  position: absolute;
  top: -45px;
  right: -30px;
  font-size: 12px;
  color: #ffffff;
}

.counter-bar
  .skill-item
  .skill-bar
  .bar-inner
  .progress-line
  .skill-percent::after {
  content: "";
  position: absolute;
  left: -7px;
  top: -2px;
  width: 40px;
  height: 25px;
  background: #0e0e0e;
  line-height: 20px;
  text-align: center;
  z-index: -1;
}

.counter-bar
  .skill-item
  .skill-bar
  .bar-inner
  .progress-line
  .skill-percent::before {
  position: absolute;
  content: "";
  border-top: 20px solid #0e0e0e;
  width: 0;
  height: 0;
  bottom: -12px;
  left: 0;
  border-right: 15px solid transparent;
  transform: translateX(-50%);
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .taskplus-skill-section .skill-left {
    padding-right: 30px;
  }
  .taskplus-skill-section .skill-right .experience .number {
    font-size: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-skill-section .skill-left {
    padding-right: 0px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-skill-section .skill-right {
    padding-right: 0px;
  }
  .taskplus-skill-section
    .counter-bar
    .skill-item
    .skill-bar
    .bar-inner
    .progress-line
    .skill-percent {
    right: -15px;
  }
}

.taskplus-skill-section-02 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  z-index: 5;
  overflow: hidden;
}

.taskplus-skill-section-02 .skill-left {
  padding-right: 25px;
}

.taskplus-skill-section-02 .skill-left .experience-wrap {
  display: flex;
  margin-top: 35px;
}

.taskplus-skill-section-02 .skill-left .experience-wrap .experience {
  max-width: 170px;
  background: linear-gradient(-120deg, #43baff 0%, #7141b1 100%);
  padding: 30px;
}

.taskplus-skill-section-02 .skill-left .experience-wrap .experience .number {
  font-size: 100px;
  line-height: 54px;
  font-weight: 600;
  color: #ffffff;
}

.taskplus-skill-section-02 .skill-left .experience-wrap .experience span {
  font-size: 18px;
  line-height: 24px;
  margin-top: 30px;
  display: inline-block;
  color: #ffffff;
}

.taskplus-skill-section-02 .skill-left .experience-wrap .experience-text {
  flex: 1;
  margin-left: 55px;
}

.taskplus-skill-section-02 .skill-left .experience-wrap .experience-text p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 0;
}

.taskplus-skill-section-02
  .skill-left
  .experience-wrap
  .experience-text
  .learn-more {
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  color: #086ad8;
  margin-top: 20px;
}

.taskplus-skill-section-02
  .skill-left
  .experience-wrap
  .experience-text
  .learn-more
  i {
  position: relative;
  top: 1px;
  transition: all 0.3s linear;
}

.taskplus-skill-section-02
  .skill-left
  .experience-wrap
  .experience-text
  .learn-more:hover
  i {
  margin-left: 5px;
}

.taskplus-skill-section-02 .skill-right {
  margin-top: 0;
}

.taskplus-skill-section-02 .skill-right .counter-bar {
  padding-top: 0px;
}

.taskplus-skill-section-02
  .skill-right
  .counter-bar
  .skill-item
  .skill-bar
  .bar-inner
  .progress-line {
  /* background: #086ad8; */
}

@media only screen and (max-width: 1199px) {
  .taskplus-skill-section-02 .skill-left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-skill-section-02 .skill-right {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-skill-section-02 .skill-left .experience-wrap {
    flex-direction: column;
    gap: 25px;
  }
  .taskplus-skill-section-02 .skill-left .experience-wrap .experience-text {
    margin-left: 0px;
  }
}

.taskplus-skill-section-03 .skill-left {
  padding-right: 60px;
}

.taskplus-skill-section-03 .skill-left .about-list {
  margin-top: 20px;
}

.taskplus-skill-section-03 .skill-left .about-author-info-wrap {
  margin-right: 30px;
}

.taskplus-skill-section-03 .skill-right {
  padding-right: 120px;
}

.taskplus-skill-section-03 .skill-right .text {
  font-size: 18px;
  line-height: 30px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-skill-section-03 .skill-left {
    padding-right: 30px;
  }
  .taskplus-skill-section-03 .skill-right {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-skill-section-03 .skill-left {
    padding-right: 0px;
  }
  .taskplus-skill-section-03 .skill-left .about-author-info-wrap {
    margin-right: 0px;
  }
}

/*--
/*  03 - About CSS
/*----------------------------------------*/
.taskplus-about-section {
  background: #0c0520;
  padding-top: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.taskplus-about-section .shape-1 {
  position: absolute;
  width: 100%;
  height: 130px;
  right: 0;
  left: 0px;
  top: -30px;
  background: #ffffff;
  transform: skewY(-3deg);
  transform-origin: 0;
  z-index: 2;
}

.taskplus-about-section .about-img-wrap {
  position: relative;
}

.taskplus-about-section .about-img-wrap .about-img img {
  border-radius: 10px;
}

.taskplus-about-section .about-img-wrap .about-img.about-img-big {
  padding-top: 80px;
}

.taskplus-about-section .about-img-wrap .about-img.about-img-sm {
  position: absolute;
  top: 0px;
  right: 0;
}

.taskplus-about-section .about-img-wrap .about-img.about-img-sm .shape-01 {
  position: absolute;
  width: 9px;
  height: 235px;
  background: #086ad8;
  right: 10px;
  bottom: -270px;
}

.taskplus-about-section .about-content-wrap {
  padding-left: 100px;
}

.taskplus-about-section .about-content-wrap .section-title .title {
  color: #ffffff;
}

.taskplus-about-section .about-content-wrap .section-title .sub-title {
  color: #ffffff;
}

.taskplus-about-section .about-content-wrap p {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 15px;
  margin-top: 20px;
}

.taskplus-about-section .about-content-wrap .play-btn {
  margin-top: 60px;
}

.taskplus-about-section .about-content-wrap .play-btn a {
  position: relative;
}

.taskplus-about-section .about-content-wrap .play-btn a i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: linear-gradient(240deg, #086ad8 0%, #42b9ff 100%);
  color: #ffffff;
  transition: all 0.3s linear;
}

.taskplus-about-section .about-content-wrap .play-btn a span {
  font-size: 24px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #ffffff;
  margin-left: 30px;
}

.taskplus-about-section .about-content-wrap .play-btn a::before {
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 0;
  left: 16%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
  animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.taskplus-about-section .about-content-wrap .play-btn a::after {
  content: "";
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 0;
  left: 16%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
  animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.taskplus-about-section .about-content-wrap .play-btn a:hover i {
  background: linear-gradient(180deg, #086ad8 0%, #42b9ff 100%);
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section {
    padding-top: 165px;
  }
  .taskplus-about-section .shape-1 {
    top: -60px;
  }
  .taskplus-about-section .about-img-wrap .about-img.about-img-sm {
    right: -25px;
  }
  .taskplus-about-section .about-img-wrap .about-img.about-img-sm .shape-01 {
    height: 190px;
    bottom: -228px;
  }
  .taskplus-about-section .about-content-wrap {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section {
    padding-top: 135px;
  }
  .taskplus-about-section .about-content-wrap {
    padding-left: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section {
    padding-top: 150px;
  }
  .taskplus-about-section .about-img-wrap .about-img.about-img-big {
    padding-top: 0px;
  }
  .taskplus-about-section .about-img-wrap .about-img.about-img-sm {
    display: none;
  }
}

@-webkit-keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: 0.5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

@keyframes zoombig {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: 0.5;
    border-width: 3px;
  }
  65% {
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 2px;
  }
}

.taskplus-about-section-02 .about-02-left {
  padding: 0 35px;
}

.taskplus-about-section-02 .about-02-left .section-title .title {
  font-size: 42px;
  line-height: 60px;
}

.taskplus-about-section-02 .about-02-left .about-author {
  margin-top: 30px;
}

.taskplus-about-section-02 .about-02-left .about-author .name {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.taskplus-about-section-02 .about-02-left .about-author .designation {
  font-size: 12px;
  line-height: 18px;
  color: #4c4d56;
}

.taskplus-about-section-02 .about-02-right {
  position: relative;
  padding-left: 112px;
}

.taskplus-about-section-02 .about-02-right::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #e1e1e1;
  width: 1px;
  height: 350px;
}

.taskplus-about-section-02 .about-02-right p {
  font-size: 16px;
  line-height: 30px;
  color: #4c4d56;
}

.about-list {
  margin-top: 40px;
}

.about-list ul li {
  font-size: 16px;
  line-height: 36px;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  color: #333333;
}

.about-list ul li i {
  font-size: 16px;
  color: #086ad8;
  margin-right: 10px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-02 .about-02-right {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-02 .about-02-left {
    padding: 0 0px;
  }
  .taskplus-about-section-02 .about-02-right {
    padding-left: 0px;
    margin-top: 50px;
  }
  .taskplus-about-section-02 .about-02-right::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-02 .about-02-left .section-title .title {
    font-size: 36px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-02 .about-02-left .section-title .title {
    font-size: 30px;
    line-height: 48px;
  }
}

.taskplus-about-section-03 {
  overflow: hidden;
  padding-bottom: 180px;
}

.taskplus-about-section-03 .about-content-03 {
  padding-right: 145px;
}

.taskplus-about-section-03 .about-content-03 .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.taskplus-about-section-03 .about-content-03 .about-quote {
  background: #fff6f5;
  padding: 40px 30px;
  padding-right: 85px;
  border-radius: 5px;
  margin-top: 30px;
}

.taskplus-about-section-03 .about-content-03 .about-quote .blockquote {
  padding-left: 60px;
  margin-bottom: 0;
  position: relative;
}

.taskplus-about-section-03 .about-content-03 .about-quote .blockquote::before {
  content: "\f115";
  font-family: flaticon !important;
  font-size: 40px;
  line-height: 1;
  margin-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ff5538;
}

.taskplus-about-section-03 .about-content-03 .about-quote .blockquote p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  color: #0e0e0e;
}

.taskplus-about-section-03 .about-content-03 .about-list-02 {
  padding-top: 20px;
  padding-right: 25px;
}

.taskplus-about-section-03
  .about-content-03
  .about-list-02
  .about-list-item-02 {
  margin-top: 20px;
}

.taskplus-about-section-03
  .about-content-03
  .about-list-02
  .about-list-item-02
  .title {
  font-size: 18px;
  line-height: 30px;
  color: #231e32;
}

.taskplus-about-section-03
  .about-content-03
  .about-list-02
  .about-list-item-02
  .title
  i {
  color: #ff5538;
  margin-right: 7px;
}

.taskplus-about-section-03
  .about-content-03
  .about-list-02
  .about-list-item-02
  P {
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
}

.taskplus-about-section-03 .about-img-warp-3 {
  position: relative;
  z-index: 1;
}

.taskplus-about-section-03 .about-img-warp-3 .shape-1 {
  width: 520px;
  height: 490px;
  background: #ff5538;
  position: absolute;
  left: 85px;
  bottom: -30px;
  z-index: -1;
}

.taskplus-about-section-03 .about-img-warp-3 .about-img img {
  border-radius: 10px;
}

.taskplus-about-section-03 .about-img-warp-3 .about-img-big {
  text-align: right;
}

.taskplus-about-section-03 .about-img-warp-3 .about-img-sm {
  position: absolute;
  left: 0px;
  bottom: -65px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-about-section-03 .about-content-03 {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-03 {
    padding-bottom: 100px;
  }
  .taskplus-about-section-03 .about-content-03 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-03 {
    padding-bottom: 180px;
  }
  .taskplus-about-section-03 .about-content-03 {
    padding-right: 140px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-03 {
    padding-bottom: 150px;
  }
  .taskplus-about-section-03 .about-content-03 {
    padding-right: 0px;
  }
  .taskplus-about-section-03 .about-img-warp-3 .shape-1 {
    left: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-03 {
    padding-bottom: 70px;
  }
  .taskplus-about-section-03 .about-content-03 .about-quote {
    padding-right: 35px;
  }
  .taskplus-about-section-03 .about-img-warp-3 .shape-1 {
    display: none;
  }
  .taskplus-about-section-03 .about-img-warp-3 .about-img-big {
    text-align: center;
  }
  .taskplus-about-section-03 .about-img-warp-3 .about-img-sm {
    display: none;
  }
}

.taskplus-about-section-04 .about-content-wrap {
  padding-right: 105px;
}

.taskplus-about-section-04 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 30px;
}

.taskplus-about-section-04 .about-img-wrap {
  position: relative;
}

.taskplus-about-section-04 .about-img-wrap .about-img img {
  border-radius: 10px;
}

.taskplus-about-section-04 .about-img-wrap .about-img-big {
  text-align: right;
}

.taskplus-about-section-04 .about-img-wrap .about-img-sm {
  position: absolute;
  left: -15px;
  bottom: 35px;
  border: 8px solid #ffffff;
  border-radius: 10px;
}

.about-author-info-wrap {
  display: flex;
  align-items: center;
  border-top: 2px solid #ececec;
  margin-top: 30px;
  padding-top: 30px;
}

.about-author-info-wrap .about-author .name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.about-author-info-wrap .about-author .designation {
  font-size: 12px;
  line-height: 18px;
}

.about-author-info-wrap .about-info {
  flex: 1;
  padding-left: 50px;
  margin-left: 30px;
  position: relative;
}

.about-author-info-wrap .about-info::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ececec;
  width: 2px;
  height: 95px;
  transform: translateY(-50%);
}

.about-author-info-wrap .about-info p {
  font-size: 16px;
  line-height: 30px;
  color: #0e0e0e;
}

.about-author-info-wrap .about-info .number {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #086ad8;
}

@media only screen and (max-width: 449px) {
  .about-author-info-wrap .about-info {
    padding-left: 20px;
    margin-left: 15px;
  }
  .about-author-info-wrap .about-info p {
    font-size: 15px;
  }
}

.play-btn-02 {
  position: absolute;
  left: 15%;
  top: 16%;
}

.play-btn-02 a {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(240deg, #086ad8 0%, #42b9ff 100%);
  color: #ffffff;
  transition: all 0.3s linear;
}

.play-btn-02 a::before {
  content: "";
  border: 2px solid #086ad8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
  animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.play-btn-02 a::after {
  content: "";
  border: 2px solid #086ad8;
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  -webkit-animation: zoombig 3.25s linear infinite;
  animation: zoombig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.play-btn-02 a:hover {
  background: linear-gradient(180deg, #086ad8 0%, #42b9ff 100%);
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-04 .about-content-wrap {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-04 .about-img-wrap {
    margin-top: 60px;
  }
  .taskplus-about-section-04 .play-btn-02 {
    left: 22%;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-04 .play-btn-02 {
    left: 2%;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-04 .about-content-wrap {
    padding-right: 0px;
  }
  .taskplus-about-section-04 .about-img-wrap {
    position: relative;
  }
  .taskplus-about-section-04 .about-img-wrap .about-img-big {
    text-align: center;
  }
  .taskplus-about-section-04 .about-img-wrap .about-img-sm {
    display: none;
  }
  .taskplus-about-section-04 .play-btn-02 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.taskplus-about-section-05 .section-title {
  max-width: 1060px;
  margin: 0 auto;
}

.taskplus-about-section-05 .about-img-wrap {
  position: relative;
}

.taskplus-about-section-05 .about-img-wrap .about-img img {
  border-radius: 15px;
}

.taskplus-about-section-05 .about-img-wrap .about-img-big {
  text-align: right;
}

.taskplus-about-section-05 .about-img-wrap .about-img-sm {
  position: absolute;
  top: 80px;
  left: 0;
}

.taskplus-about-section-05 .about-content-wrap {
  padding-top: 100px;
}

.taskplus-about-section-05 .about-content {
  padding-left: 120px;
}

.taskplus-about-section-05 .about-content .title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  color: #0e0e0e;
}

.taskplus-about-section-05 .about-content .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 30px;
}

.taskplus-about-section-05 .about-content .about-list-item-wrap {
  margin-top: -20px;
}

.taskplus-about-section-05
  .about-content
  .about-list-item-wrap
  .about-list-item {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.taskplus-about-section-05
  .about-content
  .about-list-item-wrap
  .about-list-item
  .about-text {
  flex: 1;
  margin-left: 20px;
}

.taskplus-about-section-05
  .about-content
  .about-list-item-wrap
  .about-list-item
  .about-text
  .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.taskplus-about-section-05
  .about-content
  .about-list-item-wrap
  .about-list-item
  .about-text
  p {
  font-size: 14px;
  line-height: 27px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-05 .about-content-wrap {
    padding-top: 80px;
  }
  .taskplus-about-section-05 .about-content {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-05 .about-content-wrap {
    padding-top: 60px;
  }
  .taskplus-about-section-05 .about-img-wrap .about-img-big {
    text-align: center;
  }
  .taskplus-about-section-05 .about-content {
    padding-left: 0px;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-05 .about-img-wrap .about-img-sm {
    display: none;
  }
}

.taskplus-about-section-06 {
  padding-bottom: 100px;
}

.taskplus-about-section-06 .about-img {
  position: relative;
  margin-top: 70px;
  z-index: 1;
}

.taskplus-about-section-06 .about-img .shape-1 {
  position: absolute;
  left: 0;
  top: -25%;
  -webkit-animation: round-01 7s linear infinite;
  animation: round-01 7s linear infinite;
  z-index: -1;
}

.taskplus-about-section-06 .about-img .play-btn {
  position: absolute;
  top: -25px;
  right: 135px;
}

.taskplus-about-section-06 .about-img .play-btn a {
  width: 80px;
  height: 80px;
  line-height: 68px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 7px solid #ffffff;
  background: #11ddf5;
  color: #ffffff;
}

.taskplus-about-section-06 .about-img .play-btn a:hover {
  background: #0f0948;
  color: #ffffff;
}

.taskplus-about-section-06 .about-img .image {
  padding-left: 70px;
}

.taskplus-about-section-06 .about-img .image img {
  border-radius: 50%;
}

.taskplus-about-section-06 .about-content-wrap {
  padding-right: 115px;
}

.taskplus-about-section-06 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.taskplus-about-section-06 .about-content-wrap .about-list-wrap {
  /* padding-top: 50px; */
}

.taskplus-about-section-06 .about-content-wrap .about-list-wrap .about-list li {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li:last-child {
  margin-bottom: 0;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a {
  font-size: 20px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  color: #0e0e0e;
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-06
    .about-content-wrap
    .about-list-wrap
    .about-list
    li
    a {
    font-size: 18px;
  }
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a:hover {
  color: #11ddf5;
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a:hover
  i {
  transform: translateX(20px);
}

.taskplus-about-section-06
  .about-content-wrap
  .about-list-wrap
  .about-list
  li
  a
  i {
  transform: translateX(15px);
  transition: all 0.3s linear;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-06 {
    padding-bottom: 100px;
  }
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -13%;
  }
  .taskplus-about-section-06 .about-img .image {
    padding-left: 30px;
  }
  .taskplus-about-section-06 .about-content-wrap {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -25%;
    left: 11%;
  }
  .taskplus-about-section-06 .about-img .image {
    text-align: center;
    padding-left: 0px;
  }
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 150px;
  }
  .taskplus-about-section-06 .about-content-wrap .about-list-wrap {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -20%;
    left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-06 .about-img .shape-1 {
    top: -10%;
  }
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 449px) {
  .taskplus-about-section-06 .about-content-wrap {
    margin-top: 80px;
  }
}

.taskplus-about-section-07 .about-img-wrap {
  display: flex;
  position: relative;
  z-index: 1;
}

.taskplus-about-section-07 .about-img-wrap .shape-1 {
  position: absolute;
  right: 55px;
  bottom: -30px;
  z-index: -1;
}

.taskplus-about-section-07 .about-img-wrap .about-img {
  overflow: hidden;
}

.taskplus-about-section-07 .about-img-wrap .about-img img {
  border-radius: 10px;
}

.taskplus-about-section-07 .about-img-wrap .about-img:first-child {
  padding-right: 15px;
}

.taskplus-about-section-07 .about-img-wrap .about-img:last-child {
  padding-left: 15px;
  margin-top: 75px;
}

.taskplus-about-section-07 .about-content-wrap {
  padding-left: 90px;
}

.taskplus-about-section-07 .about-content-wrap .text {
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
}

.taskplus-about-section-07 .about-content-wrap .about-list-03 {
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
  padding-top: 15px;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03 {
  padding-right: 40px;
  margin-top: 25px;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03
  .title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: #231e32;
}

.taskplus-about-section-07
  .about-content-wrap
  .about-list-03
  .about-list-item-03
  p {
  font-size: 16px;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 10px;
    bottom: -30px;
    width: 370px;
  }
  .taskplus-about-section-07 .about-content-wrap {
    padding-left: 40px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-about-section-07 .about-img-wrap {
    justify-content: center;
  }
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 75px;
    width: inherit;
  }
  .taskplus-about-section-07 .about-content-wrap {
    padding-left: 0px;
    padding-top: 60px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    right: 40px;
    width: 370px;
  }
  .taskplus-about-section-07
    .about-content-wrap
    .about-list-03
    .about-list-item-03 {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-about-section-07 .about-img-wrap .shape-1 {
    display: none;
  }
}

/*--
/*  13 - Team CSS
/*----------------------------------------*/
.team-content-wrap {
  padding-top: 70px;
}

.single-team {
  position: relative;
}

.single-team .team-img a {
  position: relative;
  display: block;
}

.single-team .team-img a::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(249, 80, 0, 0) 35%,
    rgba(14, 14, 14, 0.89) 78%
  );
  z-index: 0;
  transition: all 0.5s linear;
}

.single-team .team-content {
  position: absolute;
  left: 60px;
  bottom: 40px;
  z-index: 5;
  transition: all 0.3s linear;
}

.single-team .team-content .name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.single-team .team-content .name a {
  color: #ffffff;
}

.single-team .team-content .name::before {
  content: "";
  position: absolute;
  height: 40px;
  width: 20px;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
  z-index: -1;
  transition: all 0.3s linear;
}

.single-team .team-content .designation {
  font-size: 12px;
  line-height: 24px;
  position: relative;
  z-index: 1;
  color: #ffffff;
}

.single-team .team-content .team-social {
  opacity: 0;
  visibility: hidden;
  margin-bottom: -25px;
  position: relative;
  z-index: 1;
  transition: all 0.3s linear;
}

.single-team .team-content .team-social .social li {
  display: inline-block;
}

.single-team .team-content .team-social .social li + li {
  margin-left: 10px;
}

.single-team .team-content .team-social .social li a {
  font-size: 14px;
  transform: translate3d(0, 0, 0);
  color: #ffffff;
  transition: all 0.3s linear;
}

.single-team:hover .team-content {
  bottom: 90px;
}

.single-team:hover .team-content .name::before {
  height: 145px;
  width: 80px;
  transform: translateY(-35%);
}

.single-team:hover .team-content .team-social {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
}

.single-team:hover .team-content .team-social .social li a:hover {
  transform: translateY(-5px);
}

.team-active .swiper-pagination {
  position: relative;
  margin-top: 50px;
}

.team-active .swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px;
}

.taskplus-team-section-02 {
  /* background: #010f31; */
  background-image: url("./Assets/images/bg/team-bg1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.taskplus-team-section-03 .team-wrap {
  margin-top: -30px;
}

.taskplus-team-section-03 .team-wrap .single-team {
  margin-top: 30px;
}

.taskplus-team-section-03 .team-wrap .single-team .team-img a img {
  width: 100%;
}

/*--
/*  16 - Pricing CSS
/*----------------------------------------*/
.taskplus-pricing-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.taskplus-pricing-section .pricing-wrap .pricing-content-wrap {
  padding-top: 30px;
}

.taskplus-pricing-section .pricing-wrap .pricing-content-wrap .single-pricing {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 45px 55px 70px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing::before {
  content: "";
  position: absolute;
  right: -38%;
  top: -42%;
  border: 30px solid #ffffff;
  width: 445px;
  height: 445px;
  border-radius: 50%;
  z-index: -1;
}
.pricing-content-wrap .single-pricing .pricing-badge .title {
  font-size: 36px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 1399px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing {
    padding: 45px 30px 50px;
  }
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-badge
  .title {
  font-size: 36px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #0e0e0e;
}

@media only screen and (max-width: 575px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-badge
    .title {
    font-size: 24px;
  }
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  .currency {
  font-size: 30px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  display: inline-block;
  position: relative;
  bottom: -10px;
}

@media only screen and (max-width: 991px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-price
    .currency {
    font-size: 25px;
  }
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  .price {
  font-size: 40px;
  line-height: 54px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  display: inline-block;
  color: #086ad8;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-price
  .price
  span {
  font-size: 18px;
  font-weight: 500;
  color: #959595;
}

@media only screen and (max-width: 991px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-price
    .price {
    font-size: 60px;
  }
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content {
  padding-top: 55px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-list
  li {
  font-size: 18px;
  line-height: 30px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-list
  li
  + li {
  margin-top: 10px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn {
  padding-top: 40px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing
  .pricing-content
  .pricing-btn
  .btn {
  height: 50px;
  line-height: 48px;
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  padding: 0 50px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-content
    .pricing-btn
    .btn {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-pricing-section
    .pricing-wrap
    .pricing-content-wrap
    .single-pricing
    .pricing-content
    .pricing-btn
    .btn {
    padding: 0 30px;
    font-size: 16px;
  }
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active {
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active::before {
  opacity: 0.2;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-badge
  .title {
  color: #ffffff;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  .currency {
  color: #ffffff;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  .price {
  color: #ffffff;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-price
  .price
  span {
  color: #ffffff;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-list
  li {
  color: #ffffff;
  padding-left: 5px;
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-list
  li
  i {
  color: #ffffff;
}
.pricing-list{
  list-style-type: disc;
  padding-left: 15px;
  
}

.taskplus-pricing-section
  .pricing-wrap
  .pricing-content-wrap
  .single-pricing.active
  .pricing-content
  .pricing-btn
  .pricing-contact {
  color: #5149f3;
}

/*--
/*  19 - Contact CSS
/*----------------------------------------*/
.contact .footer-section-03 .footer-widget-wrap {
  padding-top: 50px;
}
.taskplus-contact-section .contact-wrap {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 60% 100%;
}

.taskplus-contact-section .contact-wrap .contact-info .section-title .title {
  position: relative;
  padding-left: 45px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-info
  .section-title
  .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff5538;
  height: 150px;
  width: 4px;
  transform: translateY(-50%);
}

.taskplus-contact-section .contact-wrap .contact-info ul {
  padding-top: 15px;
}

.taskplus-contact-section .contact-wrap .contact-info ul li {
  margin-top: 15px;
  padding-right: 65px;
}

.taskplus-contact-section .contact-wrap .contact-info ul li:first-child {
  border-top: 0;
}

.taskplus-contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-icon
  i {
  font-size: 50px;
  color: #ff5538;
}

@media only screen and (max-width: 575px) {
  .taskplus-contact-section
    .contact-wrap
    .contact-info
    ul
    li
    .contact-info-item
    .contact-info-icon
    i {
    font-size: 42px;
  }
}

.taskplus-contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text {
  margin-left: 20px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text
  .title {
  font-size: 24px;
  line-height: 30px;
  color: #0e0e0e;
}

.taskplus-contact-section
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-text
  p {
  font-size: 16px;
  line-height: 30px;
  color: #0e0e0e;
}

.taskplus-contact-section .contact-wrap .contact-form {
  background: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 110px;
}

@media only screen and (max-width: 1399px) {
  .taskplus-contact-section .contact-wrap .contact-form {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .taskplus-contact-section .contact-wrap .contact-form {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .taskplus-contact-section .contact-wrap .contact-form {
    margin-left: 0;
    margin-top: 60px;
  }
}

.taskplus-contact-section .contact-wrap .contact-form .contact-form-wrap {
  padding: 55px 40px;
}

@media only screen and (max-width: 1199px) {
  .taskplus-contact-section .contact-wrap .contact-form .contact-form-wrap {
    padding: 40px;
  }
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap {
  margin-bottom: 30px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .sub-title {
  font-size: 14px;
  line-height: 30px;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff5538;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .title {
  font-size: 42px;
  line-height: 54px;
  font-weight: 600;
  color: #333333;
}

@media only screen and (max-width: 1199px) {
  .taskplus-contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .heading-wrap
    .title {
    font-size: 32px;
    line-height: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .taskplus-contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .heading-wrap
    .title {
    font-size: 28px;
    line-height: 40px;
  }
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form {
  margin-top: 25px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ) {
  height: 55px;
  line-height: 55px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  )::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not(
    [type="file"]
  ):-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea {
  height: 135px;
  border: 1px solid #ebebeb;
  padding-left: 15px;
  resize: none;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea::-webkit-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea:-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea::-moz-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .single-form
  textarea:-ms-input-placeholder {
  opacity: 0.95;
  font-size: 14px;
  color: #4c4d56;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .form-btn {
  margin-top: 25px;
}

.taskplus-contact-section
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .form-btn
  .btn {
  font-size: 16px;
  width: 100%;
  height: 55px;
  line-height: 50px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .taskplus-contact-section
    .contact-wrap
    .contact-form
    .contact-form-wrap
    .form-btn
    .btn {
    padding: 0 45px;
  }
}

.taskplus-contact-section-02
  .contact-wrap
  .contact-info
  .section-title
  .title::before {
  background: #086ad8;
}

.taskplus-contact-section-02
  .contact-wrap
  .contact-info
  ul
  li
  .contact-info-item
  .contact-info-icon
  i {
  color: #086ad8;
}

.taskplus-contact-section-02 .contact-wrap .contact-form {
  background: #ffffff;
  box-shadow: 0px 0px 139px 0px rgba(0, 0, 0, 0.1);
}

.taskplus-contact-section-02
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .heading-wrap
  .sub-title {
  color: #086ad8;
}

.contact-info-section .contact-info-wrap {
  margin-top: -30px;
}

.contact-info-section .contact-info-wrap .single-contact-info {
  background: #f8f8f8;
  padding: 50px 50px 75px;
  border-radius: 5px;
  margin-top: 30px;
}

@media only screen and (max-width: 1199px) {
  .contact-info-section .contact-info-wrap .single-contact-info {
    padding: 50px 40px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-section .contact-info-wrap .single-contact-info {
    padding: 50px 30px 60px;
  }
}

.contact-info-section
  .contact-info-wrap
  .single-contact-info
  .info-content
  .title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}

.contact-info-section .contact-info-wrap .single-contact-info .info-content p {
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (max-width: 1199px) {
  .contact-info-section
    .contact-info-wrap
    .single-contact-info
    .info-content
    p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-info-section
    .contact-info-wrap
    .single-contact-info
    .info-content
    p {
    font-size: 18px;
  }
}

.taskplus-contact-section-03 .contact-wrap {
  margin-bottom: -60px;
  position: relative;
  z-index: 5;
}

.taskplus-contact-section-03
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .form-btn {
  text-align: center;
}

.taskplus-contact-section-03
  .contact-wrap
  .contact-form
  .contact-form-wrap
  .form-btn
  .btn {
  width: 70%;
}

.contact-map-section .contact-map-wrap {
  margin-bottom: -8px;
}

.contact-map-section .contact-map-wrap iframe {
  height: 550px;
  width: 100%;
  filter: brightness(100%) contrast(100%) saturate(0%) blur(0px)
    hue-rotate(0deg);
}

/*--
/*  13 - Brand CSS
/*----------------------------------------*/
.brand-title {
  font-size: 26px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  color: #4c4d56;
  margin-top: -7px;
}

@media only screen and (max-width: 575px) {
  .brand-title {
    font-size: 20px;
  }
}

.brand-title span {
  color: #086ad8;
  font-weight: 700;
}

.brand-active {
  max-width: 1070px;
  width: 100%;
  margin: 45px auto 0;
}

.taskplus-brand-section-02 .brand-wrapper {
  border-top: 1px solid #e1e1e1;
  padding-top: 20px;
  padding-bottom: 120px;
}

.taskplus-brand-section-03 .brand-wrapper {
  padding-top: 90px;
}

.taskplus-brand-section-03 .brand-active {
  margin-top: 0;
}

.taskplus-brand-section-04 .brand-wrapper {
  padding-top: 0;
}

/*--
/*  13 - Blog CSS
/*----------------------------------------*/
.taskplus-blog-section {
  padding-bottom: 70px;
}

.taskplus-blog-wrap {
  padding-top: 20px;
}

.single-blog {
  margin-top: 30px;
}

.single-blog .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.single-blog .blog-image a {
  display: block;
}

.single-blog .blog-image a img {
  transition: all 0.3s linear;
}

.single-blog .blog-image .top-meta {
  position: absolute;
  top: 35px;
  left: 30px;
}

.single-blog .blog-image .top-meta .date {
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background: linear-gradient(-180deg, #43baff 0%, #7141b1 100%);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  border-radius: 5px;
  font-family: "Barlow", sans-serif;
  display: inline-block;
  text-align: center;
  line-height: 12px;
  padding: 10px;
}

.single-blog .blog-image .top-meta .date span {
  font-size: 28px;
  line-height: 18px;
  font-weight: 500;
  line-height: 30px;
  display: block;
}

.single-blog .blog-content {
  padding: 25px 30px 40px;
  background: #ffffff;
  width: 90%;
  position: relative;
  left: 50%;
  bottom: 10px;
  transform: translateX(-48%);
  margin-top: -40px;
}

@media only screen and (max-width: 1199px) {
  .single-blog .blog-content {
    padding: 25px 20px 40px;
    width: 95%;
  }
}

.single-blog .blog-content .blog-meta span {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  color: #999999;
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-meta span {
    margin-right: 12px;
  }
}

.single-blog .blog-content .blog-meta span i {
  color: #086ad8;
  margin-right: 5px;
}

.single-blog .blog-content .title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  color: #0e0e0e;
  padding-right: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: 1199px) {
  .single-blog .blog-content .title {
    padding-right: 0;
  }
}

.single-blog .blog-btn .blog-btn-link {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 15px;
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link i {
  transition: all 0.3s linear;
}

.single-blog .blog-btn .blog-btn-link:hover i {
  margin-left: 5px;
}

.single-blog:hover .blog-image a img {
  transform: scale(1.1);
}

.taskplus-blog-grid-wrap {
  margin-top: -30px;
}

/*--
/*  17 - Blog Standard CSS
/*----------------------------------------*/
.blog-standard-wrap {
  margin-top: -40px;
}

.blog-standard-wrap .blog-post-wrap {
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .blog-standard-wrap .blog-post-wrap {
    padding-right: 0;
  }
}

.blog-standard-wrap .blog-post-wrap .pagination {
  margin-top: 55px;
}

.single-blog-post {
  margin-top: 40px;
}

.single-blog-post.single-blog .blog-content {
  padding: 40px 35px 0px;
  width: 94%;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  margin-top: -60px;
}

@media only screen and (max-width: 767px) {
  .single-blog-post.single-blog .blog-content {
    padding: 40px 30px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog-post.single-blog .blog-content {
    padding: 25px 0px 0px;
    width: 100%;
    margin-top: 0;
  }
}

.single-blog-post.single-blog .blog-content .blog-meta .tag {
  font-size: 15px;
  line-height: 30px;
  font-weight: 500;
  color: #086ad8;
}

.single-blog-post.single-blog .blog-content .title {
  font-size: 36px;
  line-height: 1.1;
}

@media only screen and (max-width: 767px) {
  .single-blog-post.single-blog .blog-content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog-post.single-blog .blog-content .title {
    font-size: 26px;
  }
}

.single-blog-post.single-blog .blog-content p {
  font-size: 16px;
  line-height: 30px;
  margin-top: 20px;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link {
  font-size: 12px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: #086ad8;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 15px;
  transition: all 0.3s linear;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link i {
  transition: all 0.3s linear;
}

.single-blog-post.single-blog .blog-btn .blog-btn-link:hover i {
  margin-left: 5px;
}
/*--Page Pagination--*/
.taskplus-pagination {
  margin-top: 60px;
}

.taskplus-pagination .pagination li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  transition: all 0.3s linear;
}

.taskplus-pagination .pagination li a.active {
  background-color: #086ad8;
  color: #ffffff;
}
.nav-item.active {
  color: #086ad8 !important; /* Change text color for active link */
}

.whatsappchat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.whatsappchat-icon {
  /* font-size: 40px; */
  width: 70px;
  background-color: #fff;
  margin: 0px;
  padding: 5px;
  border: none;
  border-radius: 50px;
}

.pricingchange-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.pricingchange-tab .singletab {
  background-color: #0D71DB;
  color: #fff;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 500;
}

.pricingchange-tab .singletab:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pricingchange-tab .singletab:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pricingchange-tab .react-tabs__tab--selected {  
  background-color: #000c29;
  color: white;
}

.policy-padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .policy-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .policy-padding {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .policy-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.policy-list-items {
  list-style: inside;
}